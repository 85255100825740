.profile-dropdown {
  padding:10px;
  position: absolute;
  right:50px;
  top:70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border:1px solid rgb(204, 204, 204);
  box-shadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;
  max-height: 300px;
  width:fit-content;
  border-radius: 15px;
  background-color: white;
  z-index: 100;
}

.petsy-logo {
  width: 146px;
  height: 120px;
}

.profile-dropdown button{
  background-color: white;
  border: none;
  font-size: 14px;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.profile-dropdown button:hover {
cursor: pointer;
font-weight: bold;
}

.hidden {
  display: none;
}

#navigation-bar{
  margin-top: 30px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#navigation-bar li{
  list-style:none;
}

.navigation-bar-right {
  display: flex;
  align-items: center;
}

.nav-link{
  text-decoration: none;
  color:black;
  cursor: pointer;
  font-size: 30px;
  display: flex;
}

.nav-link i,.nav-link p{
  height:fit-content;
  margin: 0;
  margin-right: 5px;
}

#search-bar-li{
  width: 50%;
}
#search-bar-div{
  width:100%;
}
#search-bar-input{
  width:100%;
  height:30px;
  padding-left: 15px;
}

#profile-btn{
  border:1px solid rgb(101, 101, 101);
  height: 32px;
  width: 63px;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  background-color: transparent;
}

#profile-btn:hover{
  cursor: pointer;
  box-shadow: 2px 2px #d6d6d6;
  transition-delay: 4ms;
}

#profile-btn:hover #nav-user-icon {
  font-weight: bold;
}

#profile-btn:active {
  transform: translateY(1.2px);
}


#user-profile-li{
  margin-right:40px;
  margin-left: 10px;
}

#log-out-btn{
  margin:0;
  margin-top:10px;
  background-color: rgb(199, 53, 53);
  border-radius: 15px;
  width: 75px;
  height: 30px;
  font-size: 11px;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

#log-out-btn:hover{
  cursor: pointer;
  background-color: rgba(176, 47, 47, 0.995);
}

.fa-xl {
  font-size: 14px;
  margin-bottom: 1.3px;
}


.k-loggedin-container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.k-loggedin-container p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 15px;
}

.useremail {
  color: rgb(167, 167, 167);
  font-size: 10px;
  padding-bottom: 7px;
}

.k-loggedin-container li {
  padding-top: 8px;
  padding-bottom: 8px;
}

.k-loggedin-container li:hover {
  background-color: rgb(252, 252, 252);
}

#loggedin-userinfocontainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

#loggedin-userinfocontainer img {
  margin-bottom: 6px;
}

.loggedin-profilepiccontainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.loggedin-defaultprofilepic {
  width: 24px;
  height: 24px;
}

.loggedin-line {
  border-top: 1px solid #d4d4d4;
}

.nav-searchbar {
  border: 1px solid rgb(101, 101, 101);
  font-size: 11px;
  color: black;
  border-radius: 30px;
}

#nav-heart-icon {
  margin-right: 17px;
  font-size: 13px;
  border: 1px solid rgb(101, 101, 101);
  border-radius: 50%;
  padding: 7px;
}

#nav-heart-icon:hover {
  cursor: pointer;
  box-shadow: 2px 2px #d6d6d6;
  transition-delay: 4ms;
  font-weight: bold;
}

#nav-heart-icon:active {
  transform: translateY(1.2px);
}

.nav-peekingdoggo {
  position: absolute;
  height: 57px;
  top: 0.5px;
  right: 640px;
}

.underline-name {
  text-decoration: underline;
}

.signup-login-btn {
  font-size: 10px;
  max-width: 200px;
  margin-top: 10px;
}

.nav-cart{
  margin-right: 10px;
}

.signup-login-btn:hover{
  cursor: pointer;
  text-decoration: none;
  }

 @media (min-width: 2200px) {
  .nav-peekingdoggo {
    display: none;
    }
  }

  @media (max-width: 1000px) {
    .nav-peekingdoggo {
      display: none;
      }
    }
