.n-product-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: auto;
  align-items: center;
  justify-self: center;
  margin-top: 20px;
  margin-bottom: 10vh;
}

.n-product-form-wrapper h1 {
  margin-bottom: 0px;
  font-size: 27px;
}

.n-product-form-wrapper label {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.n-product-form-wrapper textarea {
  height: 100px;
  border: 1px solid black
}

.n-tag-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: .5rem;
}

.n-tag-wrapper > li {
  list-style-type: none;
}

.tag-untoggled {
  background-color: rgb(255, 211, 211);
  width: fit-content;
  border-radius: 30px;
  font-size: 13px;
  padding: 4px 10px
}

.tag-untoggled:hover {
  background-color: rgb(238, 233, 233);
  cursor: pointer;
}

.tag-toggled {
  width: fit-content;
  border-radius: 30px;
  font-size: 14px;
  padding: 4px 10px;
  background-color: rgb(151, 25, 25);
  color: white;
}

.tag-toggled:hover {
  cursor: pointer;
}

.tag-add {
  background-color: rgb(255, 211, 211);
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 30px;
  padding: .25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-add:hover {
  background-color: rgb(238, 233, 233);
  cursor: pointer;
}

.add-tag-btn {
  width: fit-content;
  background-color: rgb(173, 12, 12);
  border-radius: 5px;
  padding: 5px 12px;
  font-size: 12px;
  color: white;
}
.add-tag-btn:hover {
  cursor: pointer;
  background-color: rgb(142, 12, 12);
}

#custom-tag-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .6rem;
}

.hidden{
  display: none;
}

.show{
  display: block;
}

#product-tag-div{
  display: flex;

}

.form-imagescontainer {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.other-images {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 8px;
}

.button-form {
  margin-top: 30px;
  margin-left: 140px;
  color: white;
  background-color:rgb(173, 12, 12);
  border-radius: 4px;
  border: rgb(210, 39, 39);
  width: 140px;
  height: 34px;
}

.button-form:hover {
  cursor: pointer;
  background-color: rgb(142, 12, 12)
}

.tag-container {
  align-items: center;
}

.formdescription-tags {
  text-align: center;
}

.form-header {
  margin: 0px;
  font-size: 19px;
}

.form-subheader {
  color: rgb(151, 151, 151);
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 16px;
}

textarea {
  resize: none;
}

.catto-container {
  position: relative;
}

.catto {
  width: 90px;
  height: 90px;
  position: absolute;
  right: -52px;
  top: 80px;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
  margin-bottom: 5vh;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #dc3131;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #dc3131;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}

.taginput-error {
  padding-top: 1vh;
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
