.manageproduct-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 0px 20px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.manageproduct-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.manageproduct-image {
  width: 150px;
  height: 150px;
  border: 1px solid rgb(250, 250, 250);
  margin-top: 15px;
  margin-bottom: 20px;
  border-radius: 50%;
  transition: transform 0.05s ease-in-out;
}

.manageproduct-image:hover {
  cursor: pointer;
  transform: scale(1.1);
  /* box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px; */
}

.manageproduct-buttoncontainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
