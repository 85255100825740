

.productdetails-carousel-container {
  width: 490px;
  max-height: 440px;
  margin-top: 100px;
  margin-left: 100px;
}

.carousel .carousel-root {
  width: 100%;
  height: 100%;
}

.productdetails-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .carousel-slider {
  width: 100%;
  height: 100%;
}

.productdetails-image-container {
  width: 490px;
  height: 440px;
}

.carousel .thumbs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 300px;
  margin-top: 70px;
}

.carousel .thumbs .thumb img {
  height: 70px;
  object-fit: cover;
}

.carousel .thumbs-wrapper {
  position: absolute;
  top: -510px;
  left: -150px;
}

.carousel .control-arrow:hover {
  opacity: 1;
}

.carousel .control-prev.control-arrow {
  left: 10px;
}

.carousel .control-next.control-arrow {
  right: 10px;
}

.carousel .control-dots {
  bottom: 0px;
}
.carousel .control-dots .dot {
  width: 6px;
  height: 6px;
}

.product-details-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 22vh;
  width: 50%;
}

.productdetails-information {
  box-sizing: border-box;
  word-wrap: break-word;
  width: 390px;
  max-height: 700px;
  margin-top: 100px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.09);
  padding-left: 14px;
  padding-right: 30px;
  padding-bottom: 8.5vh;
  display: flex;
  flex-direction: column;
  margin-left: 90px;
  border: 1px solid rgb(226, 226, 226);
  position: relative;
}

.productdetails-cart {
  margin-top: 3vh;
}

.productdetails-information > h4 {
  color: rgb(0, 0, 0);
  margin: 0px;
  font-size: 14px;
}

#productdetails-price {
  font-size: 23px;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

#productdetails-name {
  margin-bottom: 1.5vh;
  font-weight: 300;
  font-size: 16.5px;
}

#productdetails-seller {
  margin-bottom: 1vh;
  font-weight: bold;
}

#productdetails-rating {
  margin-bottom: 50px;
}

#productdetails-desc {
  margin-bottom: 10px;
  font-weight: normal;
}

.productdetails-sidebar-container {
  width: 400px;
  box-sizing: border-box;
  position: relative;
  height: 540px;
}

.productdetails-reviewbutton {
  margin-left: 224px;
  margin-top: 34px;
  padding: 10px 18px;
  background-color: rgb(255, 255, 255);;
  border: 1px solid rgb(203, 40, 40);
  color: rgb(203, 40, 40);;
}

.productdetails-reviewbutton:hover {
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid rgb(203, 40, 40);;
}

#upper-div{
  position: relative;
  z-index: 0;
}

.favoritedheart {
  color: rgb(210, 39, 39);;
  position: absolute;
  top: 82px;
  left: 600px;
  border: 1px solid rgb(210, 39, 39);
  border-radius: 50%;
  padding: 7px;
  font-size: 13px;
  z-index: 1;
}

.unfavoritedheart {
  color: rgb(30, 30, 30);;
  position: absolute;
  top: 82px;
  left: 600px;
  border: 1px solid rgb(46, 46, 46);
  border-radius: 50%;
  padding: 7px;
  font-size: 13px;
  z-index: 1;
}

.favoritedheart:hover {
  cursor: pointer;
}

.unfavoritedheart:hover {
  cursor: pointer;
}

#upper-div{
  display: flex;
  width:100%;
}

#product-tag-div {
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#individual-tag{
  background-color: rgb(230, 230, 230);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 12px;
  padding: 4px 13px;
  background-color: rgb(255, 255, 255);
  color: rgb(182, 23, 23);
  border: 1px solid rgb(182, 23, 23);
}

.productdetails-tagcontainer {
  position: absolute;
  bottom: 50px;
}

._add-tag-btn {
  background-color: white;
  border: 1px solid rgb(182, 23, 23);
  color: rgb(182, 23, 23);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
  border-radius: 30px;
  font-size: 12px;

}

._add-tag-btn:hover {
  color: rgb(208, 16, 16);
  cursor: pointer;
}

#remove-tag {
  position: absolute;
  top: -2px;
  right: 5px;
  font-size: 12px;
}

#remove-tag:hover {
  cursor: pointer;
  color: red;
}

.hidden {
  display: none;
}

.productdetails-tags {
  position: absolute;
  bottom: 0px;
}

.product-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-not-found h4 {
  font-size: 32px;
}

.product-not-found img {
  width: 400px;
  /* border-radius: 50%; */
}

.cart-petsy-button {
  margin-left: 20px;
  width: 170px;
}

.productdetails-reviewcont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(210, 204, 204);
  width: 510px;
  padding-bottom: 1vh;
  position: relative;
}

.productdetails-reviewcont p {
  color: rgb(156, 154, 154);
  font-size: 13px;

  margin-right: 0;
}

.review-button {
  margin-left: 0px;
  width: 150px;
}

.pd-descriptiondiv {
  margin: 0;
  margin-top: 1.5vh;
}

.pd-descriptiondiv p {
  margin: 0;
  font-weight: bold;
  margin-bottom: 1vh;
}

.pd-descriptiondiv h4 {
  margin: 0;
}

.pd-returns {
  color: rgb(182, 23, 23);
  margin-bottom: 0;
  margin-top: 0;
}

.pd-sellerawardscont {
  display: flex;
  flex-direction: column;
}

.pd-sellerawardone {
  border: 1px solid red;
  display: flex;
  width: 320px;
}

.pd-sellerawardone p {
  font-size: 10px;
}

#fav-button {
  z-index: 100;
  margin-top: 2vh;
  background-color: rgb(124, 122, 122)
}

#fav-button:hover {
  background-color: rgb(88, 86, 86);
}

.i-am-tired {
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 10px;
  width: 370px;
  position: absolute;
  right: -80px;
  bottom: 30px;
}

.i-am-tired p {
  font-size: 12px;
}

@media (max-width: 1299px) {
  .product-details-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 22vh;
    width: 87%;
  }
}

@media (min-width: 1300px) and (max-width: 1550px) {
  .product-details-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 22vh;
    width: 72%;
  }
}


@media (min-width: 1551px) and (max-width: 1600px) {
  .product-details-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 22vh;
    width: 60%;
  }
}

@media (min-width: 2100px) {
  .product-details-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 22vh;
    width: 39%;
  }
}
