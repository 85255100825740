#login-modal-div {
  display: flex;
  flex-direction: column;
  width: 300px;
  align-items: center;
  padding-bottom: 30px;
  border-radius: 15px;
  padding-left: 24px;
  padding-right: 24px;
  border: 2px solid rgb(101, 101, 101);;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 4px;
}

#login-modal-form {
  display: flex;
  flex-direction: column;
  width: 90%;
}

#login-modal-form label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

#login-form-submit-btn, #demo-submit-btn {
  height: 40px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  font-weight: bold;
}

#login-form-submit-btn:hover,
#demo-submit-btn:hover{
  background-color: rgb(199, 53, 53);
    cursor: pointer;
}

#login-modal-div input{
  height:25px;
  border-radius: 5px;
}
