#selection-div{
  border-radius: 10px;
  margin:10px 20px;
  border: 1px solid rgb(208, 208, 208);
}

#selection-img-div{
  overflow: hidden;
  gap: 10px;
}

#selection-img{
  width: 300px;
  height: 300px;
  object-fit: cover
}

#selection-div:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.selection-info {
  font-size: 14px;
  margin-left: 9px;
  margin-bottom: 14px;
}

.selection-info p {
  margin-top: 5px;
  margin-bottom: 0px;
  line-height: 17px;
}

.selection-name {
  font-weight: bold;
}

.selection-seller {
  font-weight: normal;
  color: rgb(169, 169, 169);
}

.star-rating-container {
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-top: 5px;
  padding: 0px;
  height: 22px;
}

.fa-reviewstar {
  color: rgb(212, 25, 25)
}

.selection-name-likes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* border: 1px solid red; */
  margin-right: 10px;
  width: 245px;
}

.fa-xs {
  color: rgb(212, 25, 25);
}

.selection-likes {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  width: 30px;
  position: absolute;
  top: 12px;
  right: -41px;
  row-gap: 2px;
}

.selection-likes p {
  color: rgb(212, 25, 25);
  font-size:11px;
  margin-left: 1.2px;
}

.productdetail-star {
  margin-bottom: 20px;
}
