.reviews-container {
  padding-left: 100px;
  padding-right: 400px;
  width: 100px;
}

.reviews {
  list-style: none;
  /* border-bottom: solid 1px rgb(146, 144, 144); */
}

.reviews > li {
  padding-top: 10px;
}

.review-user-date{
  color: #5c5d5e;
  font-size: 13px;
  margin-top: 0px;
}

.review-stars{
  margin: 20px 0px 10px 0px;
}
.reviews-count-rating-header{
  margin-top: 50px;
  position: relative;
  width: 500px;
}

.productdetails-update-review-reviewbuttons{
  background-color: transparent;
  border: none;
  position: absolute;
  top: 90px;
  left: 111px;
  width: 1000px;
}

.productdetails-update-reviewbutton{
  background-color: transparent;
  border: none;
  border-right: 1px gray solid;
  padding-left: 0px;
  color: rgb(95, 94, 94);
}
.productdetails-update-reviewbutton:hover {
  cursor: pointer;
  color: rgb(212, 25, 25);;
}

.productdetails-delete-reviewbutton{
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  color: rgb(95, 94, 94);
}

.productdetails-delete-reviewbutton:hover {
  cursor: pointer;
  color: rgb(212, 25, 25);
}

.singlereview-container {
  display: flex;
  align-items: flex-start;
  width: 500px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgb(198, 198, 198);
}

.singlereview-info {
  display: flex;
  flex-direction: column;
  max-height: 700px;
  width: 400px;
}

.review-profilepicture {
  margin-top: 2ch;
  margin-bottom: 16px;
  margin-right: 10px;
  height: 30px;
  width: 30px;
  display: flex;
}

.review-details {
  margin-top: 6px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  max-height: 400px;
}

.reviewstar-holder {
  display: flex;
  width: 220px;
  align-items: center;
  column-gap: 14px;
}
