#signup-modal-div {
  display: flex;
  flex-direction: column;
  width: 300px;
  align-items: center;
  padding-bottom: 42px;
  border-radius: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

#signup-modal-form {
  display: flex;
  flex-direction: column;
  width: 90%;
}

#signup-modal-form label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

#signup-form-submit-btn {
  height: 40px;
  margin-top: 10px;
  border-radius: 15px;
  font-weight: bold;
  background-color: rgb(245, 245, 245);
  width:100%;
}

#signup-form-submit-btn:hover{
  background-color: rgb(199, 53, 53);
  cursor: pointer;
}

#signup-modal-div input {
  height: 25px;
  border-radius: 5px;
}

#error-msg{
  color:red;
  margin:0;
  margin-top: 5px;
  font-size: 12px;
}

.peepingcats {
  position: absolute;
  top: -60px;
  right: 25px;
  width: 290px;
}
