:root {
  --tooltip-text-color: rgb(255, 255, 255);
  --tooltip-background-color: rgb(195, 28, 28);
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 4px;
}

.Tooltip-Wrapper {
  position: relative;
}

.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 12px;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}

.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}
