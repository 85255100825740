.deletemodal-container {
  display: flex;
  flex-direction: column;
  padding: 24px 26px;
  padding-bottom: 30px;
}

.deletemodal-container h1 {
  text-align: center;
  margin-bottom: 0px;
}

.deletemodal-container h4 {
  margin-top: 7px;
}

.confirm-yes {
  background-color: rgb(58, 172, 10);
  color: white;
  padding: 10px 12px;
  font-weight: bold;
  border: none;
  margin-bottom: 10px;
}

.confirm-yes:hover {
  cursor: pointer;
  font-weight: bold;
  background-color:rgb(51, 148, 9);
}

.confirm-no {
  background-color: rgb(191, 23, 23);
  color: white;
  padding: 10px 12px;
  border: none;
}

.confirm-no:hover {
  cursor: pointer;
  background-color: rgb(151, 20, 20)
}
