.review-form{
  margin: 20px;
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 90px 20px;
}

.form-title {
  text-align: center;
  color:rgb(210, 39, 39);
  margin-bottom: 0px;
}

.product-name-img{
  display: flex;
  flex-direction: column;
}

.product-name {
  color: rgb(84, 83, 83);
}

.product-img{
  width: 200px;
  height: 200px;
}

.on {
  border: none;
  color:rgb(210, 39, 39);
}

.on:hover {
  cursor: pointer;
}

.off{
  border: none;
  color: #7d7b7b;
}

.star-rating > button {
  background-color: white;
  font-size: 15px;
}

.rating-star-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.product-review-textbox-header{
  font-weight: 500;
}

.update-create-submit-button{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 15px;
}

.update-create-submit-button > button {
  background-color: rgb(254, 251, 251);
  border: 1px solid rgb(161, 8, 8);
  color: rgb(161, 8, 8);
  border-radius: 5px;
  size: 2px;
  font-size: 13px;
  padding: 5px 12px;
  cursor: pointer;
}

.update-create-submit-button > button:hover {
  background-color: rgb(161, 8, 8);
  border: 1px solid rgb(161, 8, 8);
  color: white;
  border-radius: 5px;
  size: 2px;
  font-size: 13px;
  padding: 5px 12px;
  cursor: pointer;
}

.reviewratingstar {
  width: 23px;
  height: 23px;
}

.stars-error {
  display: flex;
  align-items: center;
  justify-content: center;
}
