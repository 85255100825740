/* TODO Add site wide styles */

html, body {
  height: 100%;
  margin: 0;
}

#gray-bar {
  border-bottom: 1px solid rgb(217, 217, 217);
  padding-top: 10px;
}

#page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column
}

#content-wrap {
  flex: 1;
}

.cart-item {
  width: 20vw;
}
