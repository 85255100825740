.k-productcard-container {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  align-items: center;
  /* border-radius: 5px; */
  overflow: hidden;
}

#all-products-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 17.5vh;
}


#product-image-div {
  width: 100%;
  height:210px;
  overflow: hidden;
  display:flex;
  justify-content: center;
  align-items: center;
}

.k-productcard-image {
  width: 300px;
  /* height: 340px;
  object-fit: fill; */
}

.product-info-name {
  font-size: 14px;
  font-weight: 500;

}

.k-productcard-container:hover {
  cursor: pointer;
  transform: scale(1.02);
}

#product-info-div{
  width:90%;
  margin-right: 22px;
}

#product-info-div h4{
  padding:0;
  margin:0;
  margin-top:10px;
  margin-bottom: 0px;
}

#productcard-price{
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 10px;
}

#product-info-div h4:hover{
  text-decoration: underline;
}

#productcard-stars {
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0px;
}

#productcard-seller {
  color: rgb(60, 60, 60);
  margin-left: 5px;
  margin-top: 13px;
  font-size: 11px;
}
